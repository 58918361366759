<script setup lang="ts">
const isOpened = useScrollLock(document, false)

defineExpose({ isOpened })
</script>

<template>
  <div class="flex aspect-square h-full items-center justify-center bg-secondary text-secondary-foreground" @click="isOpened = !isOpened">
    <Icon :name="isOpened ? 'carbon:close-large' : 'carbon:menu'" size="2em" />

    <div
      :class="{
        'fixed inset-0 top-20 flex h-[calc(100vh-5rem)] flex-col items-stretch justify-between bg-white px-8 pb-12 pt-4': isOpened,
        'hidden': !isOpened,
      }"
    >
      <LazyAppMenu hide-active-indicator />

      <LazyAppMenuCta class="!inline-flex !h-16" />
    </div>
  </div>
</template>
