<script setup lang="ts">
withDefaults(defineProps<{
  hideActiveIndicator?: boolean
}>(), {
  hideActiveIndicator: false,
})

const links = [
  { name: 'Právní služby', path: '/pravni-sluzby' },
  { name: 'O nás', path: '/o-nas' },
  { name: 'Náš tým', path: '/nas-tym' },
  { name: 'Blog', path: '/blog' },
  { name: 'Kontakty', path: '/kontakty' },
]
</script>

<template>
  <nav class="self-stretch">
    <ul class="flex h-full flex-col items-stretch gap-x-14 xl:flex-row">
      <li
        v-for="link, index in links"
        :key="link.path"
        class="relative place-content-center text-xs font-bold uppercase tracking-widest text-black/90 xl:text-white/75"
      >
        <hr v-if="index !== 0" class="xl:hidden">

        <NuxtLink
          :active-class="hideActiveIndicator ? 'text-primary xl:text-white' : 'text-primary xl:text-white after:absolute after:inset-x-0 after:top-0 after:h-1.5 after:bg-primary'"
          class="flex size-full items-center justify-center py-6 xl:py-0"
          prefetch-on="interaction"
          :to="link.path"
        >
          <span>{{ link.name }}</span>
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>
