<template>
  <div>
    <AppHeader />

    <main>
      <slot />
    </main>

    <LazyAppFooter />
  </div>
</template>
