<script setup lang="ts">
const { y } = useWindowScroll()

const mobileMenuEl = useTemplateRef('mobileMenu')

const top = computed(() => {
  return y.value < 48 ? 48 - y.value : 0
})
</script>

<template>
  <div class="animate-fade-in sticky inset-x-0 top-0 z-50 flex h-20 items-center justify-between bg-primary !px-0 shadow-header xl:container xl:fixed xl:top-[--header-top] xl:h-24" :style="{ '--header-top': `${top}px` }">
    <div class="flex h-full items-stretch gap-x-28 pl-8">
      <NuxtLink
        aria-label="Link to AK Tutter homepage"
        class="self-center"
        to="/"
        @click="mobileMenuEl.isOpened = false"
      >
        <NuxtImg
          alt="Logo AK Tutter"
          class="w-[136px] xl:w-[150px]"
          height="43"
          :preload="{ fetchPriority: 'high' }"
          src="/images/logo.svg"
          width="150"
        />
      </NuxtLink>

      <AppMenu class="hidden xl:block" />
    </div>

    <AppMenuCta class="hidden xl:flex" />

    <AppMenuMobile ref="mobileMenu" class="xl:hidden" />
  </div>
</template>
